<template>
  <div class="p-6 -ml-[22px] md:-ml-[5px]">
    <!-- Tabla -->
    <table class="w-[324px] md:min-w-full border-collapse shadow-lg rounded-xl overflow-hidden text-base md:text-lg">
      <thead class="bg-[#ABE29B]">
        <tr>
          <th v-for="(header, index) in filteredHeaders" :key="index" class="py-2 px-4 text-center text-black">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr v-for="(row, index) in filteredRows" :key="index" @click="handleRowClick(row)"
          class="cursor-pointer hover:bg-yellow-100">
          <td v-for="(value, key) in row" :key="key" class="py-2 px-4 text-center align-middle">
            {{ value }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import methods from '@/methods';
export default {
  name: "DataTable",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    visibleColumnsMobile: {
      type: Array,
      required: true,
    },
    baseRoute: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visibleColumns: [],
    };
  },
  computed: {
    filteredHeaders() {
      return this.headers.filter((_, index) =>
        this.visibleColumns.includes(index)
      );
    },
    filteredRows() {
      return this.rows.map((row) => {
        const filteredRow = {};
        Object.keys(row).forEach((key, index) => {
          if (this.visibleColumns.includes(index)) {
            filteredRow[key] = row[key];
          }
        });
        return filteredRow;
      });
    },
  },
  mounted() {
    // Columnas visibles
    this.setVisibleColumns();
    // Actualización de columnas
    window.addEventListener("resize", this.setVisibleColumns);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.setVisibleColumns);
  },
  methods: {
    setVisibleColumns() {
      if (window.innerWidth < 768) {
        this.visibleColumns = this.visibleColumnsMobile;
      } else {
        this.visibleColumns = this.headers.map((_, index) => index);
      }
    },
    handleRowClick(row) {
      //si la tabla tiene role quiere decir que es un usuario
      if (row.role) {
        this.$axios(`/get-user/${row.id}`).then(response => {
          methods.updateAuxProperties(this.$auxiliar, response.data.user);
          this.$router.push(`${this.baseRoute}`);
        }).catch(error => {
          console.error('Error al obtener la información:', error.response);
          alert(error.response.data.message);
        });
      }
    },
  },
};
</script>
