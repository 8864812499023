export default {
    updateAuxProperties(auxiliar, data) {
        auxiliar.id = data.id;
        auxiliar.name = data.name;
        auxiliar.role = data.role;
        auxiliar.phone = data.phone;
        auxiliar.active = data.active;
    },
    deleteAuxProperties(auxiliar) {
        auxiliar.id = null;
        auxiliar.name = null;
        auxiliar.role = null;
        auxiliar.phone = null;
        auxiliar.active = null;
    },
}